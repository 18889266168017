import React from "react"
import "./TopListCard.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function TopListCard(props) {
  const { title, image, color, slug, subtitle } = props
  return (
    <div className={`top-list-card`}>
      <Link to={`/top-lijsten/${slug}`} className="top-list-card__link">
        <div className="top-list-card__inner" style={{ backgroundColor: color }}>
          <div className="top-list-card__content">
            {subtitle && (
              <h3 className="top-list-card--subtitle">{subtitle}</h3>
            )}
            <h3 className="top-list-card--title">{title}</h3>
          </div>
          <div className="top-list-card__visual">
            <img src={image} alt={title} />
          </div>
        </div>
      </Link>
    </div>
  )
}

TopListCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  slug: PropTypes.string,
}

export default TopListCard
