import React, { useEffect, useState } from "react"
import "./TopListsOverview.scss"
import { useLocation } from "@reach/router"
import TopListsGrid from "../../components/Grid/TopListsGrid"
import { useGuestApi } from "../../hooks/useApi"
import withGuestLayout from "../../hoc/withGuestLayout"
import { imageUrl } from "../../helpers/ImageUrl"
import { getSingleImage } from "../../helpers/Publications"

function TopListsOverview() {
  const history = useLocation()

  const { getTopListsPublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getTopListsPublication().then(response => {
      setContent(response);
    })
  }, [getTopListsPublication])

  if(!content) {
    return null;
  }

  return (

      <div className="top-lists container">
        <div className="top-lists__hero">
          <img src={
            imageUrl(
                getSingleImage(
                  content?.image
                ),
                1200,
                392
              )} alt=""
          />
        </div>
        <div className="top-lists__grid">
          <TopListsGrid history={history} />
        </div>
      </div>

  )
}

export default withGuestLayout(TopListsOverview, {
  page: "Top lijsten",
})
