import React, { useState, useEffect } from "react"
import BtnRounded from "../Buttons/BtnRounded"
import "./Grid.scss"
import { useGuestApi } from "../../hooks/useApi"
import {
  getTitle,
  getSingleImage,
  getModelType,
  getSubtitle,
  getColor,
} from "../../helpers/Publications"
import { useTranslation } from "react-i18next"
import LoadingCard from "../Card/LoadingCard"
import GridEmpty from "./GridEmpty"
import { getImageType } from "../../helpers/Prepr"
import TopListCard from "../Card/TopListCard"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 25,
}

export default function TopListsGrid({ history }) {
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const { getTopListsPageGridPublications } = useGuestApi()
  const [gridItems, setGridItems] = useState([])
  useEffect(fetchTopListsPublications, [page])

  function fetchTopListsPublications() {
    getTopListsPageGridPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setGridItems(gridItems.concat(response.grid.items))
      setLoading(false)
    })
  }

  function loadMore() {
    setIsLoading(true)
    if (gridItems.length < pagination.total) {
      setPagination({
        total: pagination.total,
        limit: pagination.limit,
        skip: gridItems.length,
      })
      setPage(page + 1)
      setIsLoading(false)
    }
  }

  const large = [0, 9, 10, 19, 20, 29, 30, 39, 40, 49, 50, 59, 60]
  const medium = [
    1, 2, 7, 8, 11, 12, 17, 18, 21, 22, 27, 28, 31, 32, 37, 38, 41, 42, 47, 48,
    51, 52, 57, 58,
  ]
  const small = [
    3, 4, 5, 6, 13, 14, 15, 16, 23, 24, 25, 26, 33, 34, 35, 36, 43, 44, 45, 46,
    53, 54, 55, 56,
  ]

  function getImage(index, item) {
    if (large.includes(index)) {
      return getSingleImage(
        getImageType(
          "tile_large",
          item.image,
          null,
          null,
          null,
          item.tile_large
        )
      )
    } else if (medium.includes(index)) {
      return getSingleImage(
        getImageType(
          "tile_medium",
          item.image,
          null,
          null,
          null,
          null,
          item.tile_medium
        )
      )
    } else if (small.includes(index)) {
      return getSingleImage(
        getImageType(
          "tile_small",
          item.image,
          null,
          null,
          null,
          null,
          null,
          item.tile_small
        )
      )
    } else {
      return getSingleImage(
        getImageType(
          "tile_large",
          item.image,
          null,
          null,
          null,
          item.tile_large
        )
      )
    }
  }

  return (
    <>
      {!gridItems.length && loading && (
        <>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </>
      )}
      {!gridItems.length && !loading && (
        <GridEmpty>{t("gridEmpty.topLists")}</GridEmpty>
      )}
      {gridItems.map((item, index) => {
        return (
          <TopListCard
            key={index}
            type={getModelType(item.model_id)}
            title={getTitle(item.title)}
            image={getImage(index, item)}
            subtitle={getSubtitle(item.subtitle)}
            color={getColor(item.color)}
            slug={item.slug}
            index={index}
          />
        )
      })}
      {gridItems.length < pagination.total && (
        <div className="grid--more">
          <BtnRounded onClick={loadMore} secondary large loading={isLoading}>
            {t("list.loadMore")}
          </BtnRounded>
        </div>
      )}
    </>
  )
}
