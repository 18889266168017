import React from "react"
import "./Select.scss"

const Select = ({ options, onChange, selected, ...props }) => {
  return (
    <div className="select">
      <select onChange={onChange} {...props}>
        {options.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
