export function generateYearsPlaceholders(data) {
  const array = []
  array.push({ label: data.version_name, value: data.list_id })
  data.related_lists.map(list => {
    if (array.findIndex(item => item.value === data.list_id) === -1) {
      array.push({ label: list.version_name, value: list.list_id })
    }
  })
  return array
}

export function generateTopListId(selectedTopList) {
  if (selectedTopList) {
    const data = selectedTopList.split("-")
    if (data.length === 2) {
      return data[1]
    }
  }
  return null
}
