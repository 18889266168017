import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import withGuestLayout from "../../../src/hoc/withGuestLayout"
import { logo } from "../../constants/logo"
import { returnLayoutSection } from "../../helpers/Layout"
import { formatPageInformation } from "../../helpers/Meta"
import { getImageType } from "../../helpers/Prepr"
import {
  getColor,
  getExternalUrl,
  getPdf,
  getPublishOn,
  getSingleImage,
  getSpotify,
  getSubtitle,
  getTitle,
  getTopListId,
} from "../../helpers/Publications"
import { generateYearsPlaceholders } from "../../helpers/TopList"
import { useGuestApi } from "../../hooks/useApi"
import Error404 from "../Errors/404"
import Select from "../Forms/Select"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo"
import Modal from "../Modal/Modal"
import PostFooter from "../News/PostFooter"
import Title from "../Text/Title"
import "./TopListDetail.scss"

const initialPagination = {
  page: 1,
  limit: 25,
  total_pages: 0,
}

function Cover({ track }) {
  const [coverLoaded, setCoverLoaded] = useState(false)

  return (
    <>
      <img
        src={`${process.env.DIGITAL_OCEAN_CDN}${track.image}`}
        alt={track.title}
        onLoad={() => setCoverLoaded(true)}
        style={coverLoaded ? {} : { display: "none" }}
      />
      {!coverLoaded && (
        <div className="fake-cover">
          <Logo isCompact color={logo.neutral} />
        </div>
      )}
    </>
  )
}

function TopListDetail({ slug }) {
  const { t } = useTranslation()
  const { getPublicationBySlug, setPageInformation } = useGuestApi()
  const [topListItem, setTopListItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [tracks, setTracks] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const { getTopListTracks, searchTopListTracks } = useGuestApi()
  const [activeButtonOverlay, setActiveButtonOverlay] = useState(null)
  const [selectedTopList, setSelectedTopList] = useState(null)
  const [years, setYears] = useState([])
  const [query, setQuery] = useState("")
  const [modalVisible, setModalVisible] = useState(false)
  const [modalClosing, setModalClosing] = useState(false)
  const [youtubeID, setYoutubeID] = useState(null)
  const [topListDetail, setTopListDetail] = useState(null);

  useEffect(() => {
    if (selectedTopList && query.length === 0) {
      fetchPublications()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopList, query])

  useEffect(() => {
    if (query.length >= 3 && selectedTopList) {
      searchTopListTracks({ query: query, list_id: selectedTopList }).then(
        response => {
          setTracks(response)
        }
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(
    function fetchPublications() {
      getPublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response, "article"))
        setTopListItem(response)
        setSelectedTopList(getTopListId(response.toplist_id))
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }

        if (!response) {
          navigate("/top-lijsten")
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slug]
  )

  function fetchPublications() {
    if (selectedTopList) {
      getTopListTracks(selectedTopList, pagination).then(response => {
        setTopListDetail(response);
        setYears(generateYearsPlaceholders(response))
        pagination.page = response.page
        pagination.total_pages = response.total_pages
        setTracks(response.items)
      })
    }
  }

  function changePage(data) {
    pagination.page = data.selected + 1
    fetchPublications()
  }

  const openModal = id => {
    document.body.classList.add("modal-active")
    setModalVisible(!modalVisible)
    setYoutubeID(id)
  }

  const closeModal = () => {
    document.body.classList.remove("modal-active")
    setModalClosing(true)
    setTimeout(() => {
      setModalVisible(!modalVisible)
      setModalClosing(!setModalClosing)
    }, 300)
  }

  function checkPosition(oldVal, newVal) {
    if (Math.sign(oldVal - newVal) === 1) {
      return (
        <div className="positive">
          <svg
            width="8"
            height="11"
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.93549 2.41324L3.9102 1.24156L3.08189 2.07065L1.11107 4.04332C1.11106 4.04333 1.11105 4.04334 1.11105 4.04335C1.08575 4.06863 1.04386 4.06873 1.01845 4.04354C0.994096 4.0194 0.994007 3.98101 1.01822 3.95676C1.01822 3.95676 1.01823 3.95676 1.01823 3.95675L3.95378 1.01854C3.95382 1.01849 3.95387 1.01844 3.95392 1.01839C3.9785 0.994024 4.02143 0.993491 4.04705 1.01906L6.98202 3.95673C6.98202 3.95673 6.98202 3.95673 6.98202 3.95673C7.00625 3.98099 7.00612 4.0194 6.98182 4.04349L6.9818 4.04351C6.95637 4.06871 6.91446 4.0686 6.88918 4.0433L4.91835 2.07067L4.06464 1.21618V2.42406L4.06464 8.39617L3.93549 2.41324Z"
              fill="currentColor"
              stroke="currentColor"
            />
          </svg>
          <h5>{oldVal - newVal}</h5>
        </div>
      )
    } else if (Math.sign(oldVal - newVal) === -1) {
      return (
        <div className="negative">
          <svg
            width="8"
            height="11"
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.93549 2.41324L3.9102 1.24156L3.08189 2.07065L1.11107 4.04332C1.11106 4.04333 1.11105 4.04334 1.11105 4.04335C1.08575 4.06863 1.04386 4.06873 1.01845 4.04354C0.994096 4.0194 0.994007 3.98101 1.01822 3.95676C1.01822 3.95676 1.01823 3.95676 1.01823 3.95675L3.95378 1.01854C3.95382 1.01849 3.95387 1.01844 3.95392 1.01839C3.9785 0.994024 4.02143 0.993491 4.04705 1.01906L6.98202 3.95673C6.98202 3.95673 6.98202 3.95673 6.98202 3.95673C7.00625 3.98099 7.00612 4.0194 6.98182 4.04349L6.9818 4.04351C6.95637 4.06871 6.91446 4.0686 6.88918 4.0433L4.91835 2.07067L4.06464 1.21618V2.42406L4.06464 8.39617L3.93549 2.41324Z"
              fill="currentColor"
              stroke="currentColor"
            />
          </svg>
          <h5>{newVal - oldVal}</h5>
        </div>
      )
    } else {
      return (
        <svg
          width="8"
          height="11"
          viewBox="0 0 8 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.93549 2.41324L3.9102 1.24156L3.08189 2.07065L1.11107 4.04332C1.11106 4.04333 1.11105 4.04334 1.11105 4.04335C1.08575 4.06863 1.04386 4.06873 1.01845 4.04354C0.994096 4.0194 0.994007 3.98101 1.01822 3.95676C1.01822 3.95676 1.01823 3.95676 1.01823 3.95675L3.95378 1.01854C3.95382 1.01849 3.95387 1.01844 3.95392 1.01839C3.9785 0.994024 4.02143 0.993491 4.04705 1.01906L6.98202 3.95673C6.98202 3.95673 6.98202 3.95673 6.98202 3.95673C7.00625 3.98099 7.00612 4.0194 6.98182 4.04349L6.9818 4.04351C6.95637 4.06871 6.91446 4.0686 6.88918 4.0433L4.91835 2.07067L4.06464 1.21618V2.42406L4.06464 8.39617L3.93549 2.41324Z"
            fill="#a72ed0"
            stroke="#a72ed0"
          />
        </svg>
      )
    }
  }
  return (
    <>
      {loading && <div />}
      {topListItem && (
        <div
          className={`top-list__detail container `}
          style={{ backgroundColor: getColor(topListItem.color)}}
        >
          <div className="top-list__detail--header">
            <div className="top-list__detail--header-content">
              {getSubtitle(topListItem.subtitle) && (
                <h4 className="subtitle">
                  {getSubtitle(topListItem.subtitle)}
                </h4>
              )}
              <Title>{getTitle(topListItem.title)}</Title>
              {getExternalUrl(topListItem.external_url) && (
                <a
                  href={getExternalUrl(topListItem.external_url)}
                  target="_blank"
                  className="toplist--link header-link" rel="noreferrer"
                >
                  <span>{"Beluister online stream"}</span>
                </a>
              )}
            </div>
            <div className="top-list__detail--header-visual">
              <img
                src={getSingleImage(
                  getImageType(
                    "hero_detail",
                    topListItem.image,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    topListItem.hero_detail
                  )
                )}
                alt={getTitle(topListItem.title)}
              />
            </div>
          </div>
          <div className="top-list__detail--content">
            {topListItem.layout
              ? Array.isArray(topListItem.layout.items)
                ? topListItem.layout.items.map((item, i) => {
                    return returnLayoutSection(item, i)
                  })
                : null
              : null}
            <div className="top-list__wrap">
              <div className="toplist--links">
                <div className="toplist--links--left">
                  {years?.length > 0 &&
                    <Select
                      options={years}
                      onChange={e => {
                        setPagination(initialPagination)
                        selectedTopList(e.target.value)
                      }}
                    />
                  }
                </div>
                <div className="toplist--links--right">
                  {getSpotify(topListItem.spotify) && (
                    <a
                      href={getSpotify(topListItem.spotify)}
                      target="_blank"
                      className="toplist--link spotify" rel="noreferrer"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.0982 10.638C15.2301 8.34086 8.84979 8.12965 5.15723 9.25036C4.56427 9.43019 3.93721 9.09545 3.75752 8.5025C3.57783 7.90926 3.91228 7.28263 4.50566 7.10237C8.74447 5.81572 15.7908 6.0642 20.2439 8.70756C20.7774 9.02424 20.9523 9.71305 20.6361 10.2455C20.3197 10.7789 19.6303 10.9547 19.0982 10.638ZM18.9719 14.0407C18.7007 14.481 18.1249 14.619 17.6851 14.3488C14.4603 12.3664 9.54286 11.7923 5.72763 12.9504C5.23284 13.0998 4.71024 12.8208 4.56007 12.327C4.4109 11.8322 4.69004 11.3106 5.18383 11.1602C9.54228 9.83772 14.9603 10.4782 18.6641 12.7545C19.1039 13.0253 19.2423 13.6014 18.9719 14.0407ZM17.5032 17.3081C17.2877 17.6616 16.8272 17.7725 16.475 17.557C13.6569 15.8347 10.1101 15.4457 5.93305 16.3999C5.53054 16.4921 5.12946 16.2398 5.0376 15.8374C4.94532 15.4349 5.19666 15.0338 5.60004 14.942C10.1711 13.8969 14.0923 14.3467 17.2552 16.2795C17.6078 16.4949 17.7189 16.9556 17.5032 17.3081ZM12.0001 6.10352e-05C5.37269 6.10352e-05 0 5.37247 0 11.9998C0 18.6277 5.37269 23.9999 12.0001 23.9999C18.6276 23.9999 24 18.6277 24 11.9998C24 5.37247 18.6276 6.10352e-05 12.0001 6.10352e-05Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span>
                        Beluister via <strong>Spotify</strong>
                      </span>
                    </a>
                  )}
                  {getPdf(topListItem.pdf) && (
                    <a
                      href={getPdf(topListItem.pdf)}
                      target="_blank"
                      className="toplist--link pdf" rel="noreferrer"
                    >
                      <svg
                        width="15"
                        height="20"
                        viewBox="0 0 15 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.3901 4.85987L10.6401 1.10986C10.5698 1.03955 10.4746 1 10.375 1H2.49998C1.67271 1 1 1.67271 1 2.50001V17.5C1 18.3273 1.67271 19 2.50001 19H13C13.8273 19 14.5 18.3273 14.5 17.5V5.12499C14.5 5.02539 14.4604 4.93019 14.3901 4.85987ZM10.75 2.28029L13.2197 4.75001H11.5C11.0865 4.75001 10.75 4.41346 10.75 4.00002V2.28029ZM13.75 17.5C13.75 17.9134 13.4135 18.25 13 18.25H2.50001C2.08657 18.25 1.75002 17.9134 1.75002 17.5V2.50001C1.75002 2.08657 2.08657 1.75002 2.50001 1.75002H10V4.00002C10 4.82729 10.6727 5.5 11.5 5.5H13.75V17.5Z"
                          fill="#14191e"
                          stroke="#14191e"
                          strokeWidth="0.2"
                        />
                        <path
                          d="M9.66197 12.0083C9.31481 11.7351 8.98486 11.4542 8.76514 11.2345C8.47949 10.9489 8.22496 10.672 8.00379 10.4083C8.34878 9.34231 8.50002 8.79264 8.50002 8.49965C8.50002 7.2549 8.0503 6.99963 7.37502 6.99963C6.86195 6.99963 6.25002 7.26622 6.25002 8.53554C6.25002 9.09512 6.55655 9.77445 7.16409 10.564C7.01541 11.0177 6.84072 11.541 6.64444 12.131C6.54994 12.4141 6.44743 12.6763 6.339 12.9187C6.25076 12.9579 6.16505 12.9978 6.08229 13.0392C5.7842 13.1883 5.50113 13.3223 5.23854 13.4468C4.04102 14.0137 3.25 14.3887 3.25 15.1292C3.25 15.6668 3.83412 15.9996 4.375 15.9996C5.07225 15.9996 6.12511 15.0683 6.89416 13.4995C7.69248 13.1846 8.68495 12.9513 9.46826 12.8052C10.0959 13.2878 10.7892 13.7496 11.125 13.7496C12.0548 13.7496 12.25 13.212 12.25 12.7612C12.25 11.8746 11.237 11.8746 10.75 11.8746C10.5987 11.8746 10.193 11.9193 9.66197 12.0083ZM4.375 15.2496C4.16076 15.2496 4.01574 15.1486 3.99999 15.1292C3.99999 14.8633 4.79283 14.4876 5.5597 14.1243C5.60839 14.1012 5.65785 14.0781 5.70802 14.0543C5.14478 14.871 4.58777 15.2496 4.375 15.2496ZM7.00001 8.53554C7.00001 7.74966 7.24393 7.74966 7.37502 7.74966C7.64017 7.74966 7.75004 7.74966 7.75004 8.49965C7.75004 8.65785 7.64457 9.05336 7.45156 9.67081C7.15709 9.21743 7.00001 8.82888 7.00001 8.53554ZM7.28748 12.5661C7.31093 12.5009 7.33364 12.435 7.35562 12.3683C7.49477 11.9508 7.62003 11.5758 7.73172 11.2382C7.88736 11.4096 8.05509 11.585 8.23491 11.7648C8.30522 11.8351 8.47953 11.9933 8.71173 12.1914C8.2495 12.2921 7.7577 12.417 7.28748 12.5661ZM11.5 12.7613C11.5 12.9297 11.5 12.9996 11.1521 13.0019C11.0499 12.9799 10.8137 12.8407 10.5222 12.6422C10.628 12.6305 10.706 12.6247 10.75 12.6247C11.3041 12.6247 11.4612 12.6788 11.5 12.7613Z"
                          fill="#14191e"
                          stroke="#14191e"
                          strokeWidth="0.2"
                        />
                      </svg>
                      <span>
                        Download de <strong>pdf</strong>
                      </span>
                    </a>
                  )}
                </div>
                <div className="toplist--links--search">
                  <input
                    onChange={e => {
                      setQuery(e.target.value)
                    }}
                    type="text"
                    id="toplist_search"
                    placeholder="Zoek hier een artiest, song, ..."
                    className="toplist--search--input"
                  />
                  <label
                    htmlFor="toplist_search"
                    className="toplist--search--icon"
                  >
                    <Icon name="magnifier" />
                  </label>
                </div>
              </div>
              <div className="toplist">
                <div className="top-list__content">
                  <div className="content--body">
                    {tracks.map((track, i) => {
                      return (
                        <div className="content--row" key={i}>
                          {topListDetail?.numbered &&
                            <div className="song--position">
                              <h3 className="song--position--current">
                                {track.position < 10
                                  ? `0${track.position}`
                                  : track.position}
                              </h3>
                              <div className="song--position--prev">
                                {track?.last_position && (
                                  <>
                                    <h5 className="prev-position">
                                      {track.last_position < 10
                                        ? `0${track.last_position}`
                                        : track.last_position}
                                    </h5>
                                    <hr />
                                  </>
                                )}
                                <div className="positions-won">
                                  {track?.last_position
                                    ? checkPosition(
                                        track.last_position,
                                        track.position
                                      )
                                    : checkPosition(
                                        track.position,
                                        track.position
                                      )}
                                </div>
                              </div>
                            </div>
                          }
                          <div className="song--cover">
                            <Cover track={track} />
                          </div>
                          <div className="song--info">
                            <div className="title">
                              {track.title}{" "}
                              {/*<span className="play icon icon-play"></span>*/}
                            </div>
                            <div className="artist">{track.artist}</div>
                          </div>
                          {track.youtube && (
                            <button
                              onClick={() => openModal(track.youtube)}
                              className="song--youtube"
                            >
                              <Icon name="youtube" />
                              <span>Bekijk de clip</span>
                            </button>
                          )}
                          <div className="song--buttons">
                            {track.youtube && (
                              <button
                                className="song--buttons--trigger"
                                onClick={() =>
                                  activeButtonOverlay === i
                                    ? setActiveButtonOverlay(null)
                                    : setActiveButtonOverlay(i)
                                }
                              >
                                <svg
                                  width="3"
                                  height="12"
                                  viewBox="0 0 3 12"
                                  fill="currentColor"
                                >
                                  <circle
                                    cx="1.5"
                                    cy="10"
                                    r="1.5"
                                    transform="rotate(-90 1.5 10)"
                                  />
                                  <circle
                                    cx="1.5"
                                    cy="6"
                                    r="1.5"
                                    transform="rotate(-90 1.5 6)"
                                  />
                                  <circle
                                    cx="1.5"
                                    cy="2"
                                    r="1.5"
                                    transform="rotate(-90 1.5 2)"
                                  />
                                </svg>
                              </button>
                            )}
                            {activeButtonOverlay === i && (
                              <div className="song--buttons--overlay">
                                <>
                                  {/*<a href="" target="_blank" className="song--fragment">*/}
                                  {/*  <span className="play icon icon-play"></span>*/}
                                  {/*  <span>Bekijk de <strong>clip</strong></span>*/}
                                  {/*</a>*/}
                                  {track.youtube && (
                                    <a
                                      onClick={() => openModal(track.youtube)}
                                      className="song--youtube"
                                    >
                                      <img
                                        src={youtube}
                                        alt="Play on youtube"
                                      />
                                      <span>
                                        Bekijk de <strong>clip</strong>
                                      </span>
                                    </a>
                                  )}
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                    {tracks.length === 0 && (
                      <div className="search--noresults">
                        <p>
                          We vonden niets dat overeen komt met je huidige
                          zoekopdracht
                        </p>
                      </div>
                    )}
                  </div>
                  {query.length === 0 && (
                    <div className="content--pagination">
                      <ReactPaginate
                        previousLabel={""}
                        nextLabel={""}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pagination.total_pages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <PostFooter date={getPublishOn(topListItem.publish_on)} />
          </div>
        </div>
      )}
      {!topListItem && !loading && <Error404 />}
      <Modal
        youtube
        animating={modalClosing}
        visible={modalVisible}
        onClickClose={closeModal}
      >
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${youtubeID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    </>
  )
}

export default withGuestLayout(TopListDetail, {
  page: "Toplijsten",
  shareImage: "",
  shareDescription: "",
})
