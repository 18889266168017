import React from "react"
import { Router } from "@reach/router"
import TopListsOverview from "../components/TopLists/Overview"
import Detail from "../components/TopLists/Detail"

const TopLists = () => {
  return (
    <Router>
      <TopListsOverview path="top-lijsten" />
      <Detail path="top-lijsten/:slug" />
    </Router>
  )
}

export default TopLists
